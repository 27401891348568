@import '@aurora/shared-client/styles/_variables.pcss';

.lia-panel-standard,
.lia-panel-divider {
  padding: 0 $lia-panel-spacing-lg;

  @media (--lia-breakpoint-down-sm) {
    padding-left: $lia-panel-spacing-sm;
    padding-right: $lia-panel-spacing-sm;
  }
}

.lia-panel-divider {
  border-bottom: 1px solid var(--lia-panel-border-color);
}

.lia-panel-bubble,
.lia-panel-spaced {
  margin: 0 0 $lia-panel-spacing-xs;
}
